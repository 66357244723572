import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import { TransitionContext } from '../context/transition-context'

import FadeIn from '../fade-in'
import LeftChevronSvg from '../../images/chevron-left-small.svg'

const BackButton = () => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  return (
    <Outer>
      <FadeIn>
        <Inner>
          <LeftArrow>
            <LeftChevronSvg />
          </LeftArrow>
          <Link
            to="/availabilities"
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(`/availabilities`)
                setTransitionActive(false)
              }, 500)
            }}
          >
            Back to availabilities
          </Link>
        </Inner>
      </FadeIn>
    </Outer>
  )
}

export default BackButton

const Outer = styled.section`
  padding: 8vw 0;
`

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 67%;
  margin: 0 auto;

  @media (max-width: 1260px) {
    width: 75%;
  }

  @media (max-width: 650px) {
    width: calc(100% - 60px);
  }

  a {
    display: block;
    color: ${props => props.theme.colours.orange};
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    text-decoration: none;
    transition: color 0.8s ease;

    &:hover {
      color: ${props => props.theme.colours.darkGrey};
    }
  }
`

const LeftArrow = styled.figure`
  margin: 0 2.5rem 0 0;

  svg {
    width: 0.9rem;
    height: 1.5rem;
    stroke: ${props => props.theme.colours.orange};
    fill: transparent;
  }
`
