import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import TwoImages from './two-images'
import FeaturesFloorplans from './features-floorplans'
import MiddleImages from './middle-images'
import ImageCarousel from './image-carousel'

export const fragment = graphql`
  fragment PortfolioMainContentFragment on WordPress_PortfolioItem_Portfoliofields {
    mainContent {
      firstImages {
        imageLeft {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 900, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        imageRight {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 900, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      features {
        text
      }
      floorplan {
        image {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 900, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      middleImages {
        imageLeft {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        imageRight {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      imageCarousel {
        sourceUrl
        mediaItemId
        modified
        altText
        caption
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const MainContent = ({ firstImages, features, floorplan, middleImages, imageCarousel }) => (
  <Wrapper>
    {firstImages.imageLeft && firstImages.imageRight && <TwoImages {...firstImages} />}
    {features && floorplan && <FeaturesFloorplans features={features} floorplan={floorplan} />}
    {middleImages.imageLeft && middleImages.imageRight && <MiddleImages {...middleImages} />}
    {imageCarousel.length > 0 && <ImageCarousel imageCarousel={imageCarousel} />}
  </Wrapper>
)

export default MainContent

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`
