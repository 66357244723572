import React, { useRef, useContext } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { animated, useTrail, interpolate, useChain, useSpring } from 'react-spring'
import BezierEasing from 'bezier-easing'
import Parallax from '../parallax'
import { TransitionContext } from '../context/transition-context'

import DownChevronSvg from '../../images/chevron-down.svg'

export const fragment = graphql`
  fragment PortfolioHeroFragment on WordPress_PortfolioItem_Portfoliofields {
    hero {
      address
      image {
        altText
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const ProjectHero = ({ title, address, image }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  const logoAnimationRef = useRef()
  const logoAnimation = useSpring({
    ref: logoAnimationRef,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  })

  const metaAnimationRef = useRef()
  const metaAnimation = useSpring({
    ref: metaAnimationRef,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
    delay: 1000
  })

  // Split the text string into an array of words
  const splitTitleText = title.split(' ')
  const splitAddressText = address ? address.split(' ') : ''

  // Split the text string into an array of words
  const titleAnimationRef = useRef()
  const titleAnimation = useTrail(splitTitleText.length, {
    ref: titleAnimationRef,
    to: {
      y: 0,
      rotate: 0,
      opacity: 1
    },
    from: { rotate: 5, y: 30, opacity: 0 },
    config: {
      mass: 10,
      tension: 1000,
      friction: 200
    }
  })

  const addressAnimationRef = useRef()
  const addressAnimation = useTrail(splitAddressText.length, {
    ref: addressAnimationRef,
    to: {
      y: 0,
      rotate: 0,
      opacity: 1
    },
    from: { rotate: 5, y: 30, opacity: 0 },
    config: {
      mass: 10,
      tension: 1000,
      friction: 200
    }
  })

  useChain([titleAnimationRef, addressAnimationRef, metaAnimationRef, logoAnimationRef])

  const WipeAnimation = useSpring({
    from: { transform: 'translate3d(0%, 0, 0)' },
    to: { transform: 'translate3d(100%, 0, 0)' },
    config: {
      duration: 1200,
      easing: BezierEasing(0.785, 0.135, 0.15, 0.86)
    }
  })

  const frontImageTransform = useSpring({
    from: { transform: 'translate3d(-10%, 0, 0)' },
    to: { transform: 'translate3d(0%, 0, 0)' },
    config: {
      duration: 1300,
      easing: BezierEasing(0.785, 0.135, 0.15, 0.86)
    }
  })

  return (
    <Outer>
      <Wipe style={WipeAnimation} />
      <Back style={metaAnimation}>
        <Link
          to="/availabilities"
          onClick={event => {
            event.preventDefault()
            setTransitionActive(true)
            setTimeout(() => {
              navigate(`/availabilities`)
              setTransitionActive(false)
            }, 500)
          }}
        >
          <p>Back</p>
        </Link>
        <DownChevronSvg />
      </Back>
      <ScrollIndicator style={metaAnimation}>
        <p>Scroll</p>
        <span />
      </ScrollIndicator>
      <Inner>
        <Text>
          <Parallax speed={4}>
            <Heading>
              <animated.h1>
                {titleAnimation.map(({ rotate, y, ...rest }, index) => (
                  <Word
                    key={[index]}
                    style={{
                      ...rest,
                      transform: interpolate(
                        [y, rotate],
                        (y, rotate) => `translateY(${y}px) rotate(${rotate}deg)`
                      )
                    }}
                  >
                    {splitTitleText[index]}&nbsp;
                  </Word>
                ))}
                <br />
                {addressAnimation.map(({ rotate, y, ...rest }, index) => (
                  <Word
                    key={[index]}
                    style={{
                      ...rest,
                      transform: interpolate(
                        [y, rotate],
                        (y, rotate) => `translateY(${y}px) rotate(${rotate}deg)`
                      )
                    }}
                  >
                    {splitAddressText[index]}&nbsp;
                  </Word>
                ))}
              </animated.h1>
            </Heading>
          </Parallax>
        </Text>
      </Inner>
      <BackgroundImageContainer style={frontImageTransform}>
        <BackgroundImage
          fluid={image.imageFile.childImageSharp.fluid}
          loading="eager"
          alt={image.altText}
          style={{ position: 'absolute' }}
        />
      </BackgroundImageContainer>
    </Outer>
  )
}

export default ProjectHero

const Outer = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 5;

  @media (max-width: 1260px) {
    padding: 15rem 12.5% 5rem;
  }

  @media (max-width: 1200px) {
    height: 95vh;
  }

  @media (max-width: 650px) {
    padding: 10rem 30px 5rem;
  }
`

const Inner = styled.section`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 67%;
  z-index: 2;

  @media (max-width: 1260px) {
    width: 85%;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 20px;
  }
`

const ScrollIndicator = styled(animated.figure)`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 10px;
  right: 60px;
  margin: 0;
  transform: rotate(90deg);
  z-index: 10;

  @media (max-width: 1000px) {
    right: 0;
  }

  @media (max-width: 850px) {
    display: none;
  }

  p {
    display: block;
    color: #fff;
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    line-height: 1.2;
    margin: 0;
  }

  span {
    position: relative;
    top: -2px;
    width: 215px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-left: 10px;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
`

const BackgroundImageContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 5;
  }
`

const BackgroundImage = styled(Img)`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const Logo = styled(animated.div)`
  position: absolute;
  top: 20%;
  left: 0;
  transform: translateX(-30%);
  z-index: 10;

  @media (max-width: 1260px) {
    transform: translateX(0);
  }

  @media (max-width: 800px) {
    left: 20px;
  }

  > div {
    @media (max-width: 800px) {
      transform: translate3d(0, 0, 0) !important;
    }
  }

  svg {
    width: 30rem;

    @media (max-width: 1000px) {
      width: 35rem;
    }

    @media (max-width: 650px) {
      width: 260px;
    }
  }
`

const Heading = styled.div`
  position: relative;
  font-size: 10rem;
  color: #fff;
  word-wrap: normal;
  max-width: 1000px;
  z-index: 10;

  @media (max-width: 1700px) {
    max-width: 800px;
  }

  h1 {
    font-size: 10rem;
    letter-spacing: -0.2rem;

    @media (max-width: 800px) {
      margin-top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 650px) {
      font-size: 7.8rem;
      margin-top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 380px) {
      font-size: 5rem;
      width: 100%;
    }
  }
`

const Word = styled(animated.span)`
  display: inline-block;
  transform-origin: left bottom;
  opacity: 0;
  will-change: transform;
`

const Wipe = styled(animated.aside)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.colours.darkGrey};
  z-index: 50;
`

const Back = styled(animated.aside)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 2rem;
  transform: translate(0, -50%) rotate(90deg);
  transform-origin: center;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 650px) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  svg {
    position: relative;
    width: 1.5rem;
    left: -0.25rem;

    @media (max-width: 850px) {
      top: 0.3rem;
      transform-origin: center;
      transform: rotate(90deg);
      margin-right: 10px;
      width: 16px;
    }
  }

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    line-height: 1.4;
    color: #fff;
    margin-bottom: 0.8rem;

    @media (max-width: 850px) {
      font-size: 1.8rem;
    }
  }

  a {
    text-decoration: none;
  }
`
