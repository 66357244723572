import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useTrail, animated } from 'react-spring'
import InView from '../in-view'

import FadeIn from '../fade-in'
import Title from '../title'

const FeaturesFloorplans = ({ features, floorplan }) => {
  const ref = useRef()
  const [toggle, setToggle] = useState(false)

  const trail = useTrail(features.length, {
    opacity: toggle ? 1 : 0
  })

  return (
    <Outer>
      <ColumnWrapper>
        <InView element={ref.current} toggle={toggle} setToggle={setToggle}>
          <Title text="Features" type="h2" />
          <Highlights ref={ref}>
            {trail.map((props, index) => (
              <Highlight key={index} style={props}>
                {features[index].text}
              </Highlight>
            ))}
          </Highlights>
        </InView>
      </ColumnWrapper>
      {floorplan.image && (
        <ColumnWrapper>
          <Title text="Floorplan" type="h2" />
          <Image>
            <FadeIn>
              <Img
                fluid={floorplan.image.imageFile.childImageSharp.fluid}
                alt={floorplan.image.imageFile.alt}
              />
            </FadeIn>
          </Image>
        </ColumnWrapper>
      )}
    </Outer>
  )
}

export default FeaturesFloorplans

const Outer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 4rem 16.5% 0;

  @media (max-width: 1260px) {
    padding: 10vw 12.5%;
  }

  @media (max-width: 850px) {
    padding: 65px 30px 0;
  }

  h2 {
    font-size: 5.5rem;
    color: ${props => props.theme.colours.orange};
    letter-spacing: -0.1rem;
  }
`

const ColumnWrapper = styled.div`
  width: 45%;

  @media (max-width: 850px) {
    width: 100%;
  }

  &:nth-child(even) {
    margin-top: 12%;
    width: 49%;

    @media (max-width: 850px) {
      width: 100%;
    }
  }
`

const Image = styled.div`
  position: relative;
  width: 100%;
`

const Highlights = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 3rem 0 8rem;
`

const Highlight = styled(animated.li)`
  display: flex;
  align-items: flex-start;
  color: var(--primary);
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }

  &:before {
    content: '';
    position: relative;
    top: 0.8rem;
    display: inline-block;
    background: ${props => props.theme.colours.orange};
    width: 0.9rem;
    height: 0.9rem;
    margin-right: 1.5rem;
    flex-shrink: 0;
  }
`
