import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/single-portfolio/hero.js'
import Intro from '../components/single-portfolio/intro'
import MainContent from '../components/single-portfolio/main-content'
import Seo from '../components/seo'
import BackButton from '../components/single-portfolio/back-button'

const SinglePortfolio = ({ data, pageContext }) => (
  <Layout>
    <Seo pageTitle={pageContext.title} {...data.wordPress.portfolioItemBy.seo} />
    <Hero title={pageContext.title} {...data.wordPress.portfolioItemBy.portfolioFields.hero} />
    <Intro
      filter={data.wordPress.portfolioItemBy.portfolioFilters.edges[0].node.name}
      {...data.wordPress.portfolioItemBy.portfolioFields.intro}
    />
    <MainContent {...data.wordPress.portfolioItemBy.portfolioFields.mainContent} />
    <BackButton />
  </Layout>
)

export default SinglePortfolio

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      portfolioItemBy(portfolioItemId: $wordpressId) {
        ...SeoFragmentSinglePortfolio
        portfolioFilters(first: 1) {
          edges {
            node {
              name
            }
          }
        }
        portfolioFields {
          ...PortfolioHeroFragment
          ...PortfolioIntroFragment
          ...PortfolioMainContentFragment
        }
      }
    }
  }
`
