import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper } from 'swiper/js/swiper.esm'
import Img from 'gatsby-image'
import BezierEasing from 'bezier-easing'
import { useSpring, useChain, animated, config } from 'react-spring'
import InView from '../in-view'

import 'swiper/css/swiper.css'

const ImageCarousel = ({ imageCarousel }) => {
  const ref = useRef()
  const [toggle, setToggle] = useState(false)
  const [swiper, updateSwiper] = useState(null)

  const lineAnimationRef = useRef()
  const lineAnimation = useSpring({
    ref: lineAnimationRef,
    from: { transform: 'scaleX(0)' },
    to: { transform: 'scaleX(1)' },
    config: {
      duration: 500,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const titleAnimationRef = useRef()
  const titleAnimation = useSpring({
    ref: titleAnimationRef,
    from: { transform: 'rotate(5deg)', opacity: 0 },
    to: { transform: 'rotate(0deg)', opacity: 1 },
    config: {
      mass: 5,
      tension: 1200,
      friction: 200
    }
  })

  const maskAnimationRef = useRef()
  const maskAnimation = useSpring({
    ref: maskAnimationRef,
    from: {
      transform: `translate3d(0, 101%, 0)`
    },
    to: {
      transform: `translate3d(0, 0%, 0)`
    },
    config: config.slow
  })

  const maskAnimationInnerRef = useRef()
  const maskAnimationInner = useSpring({
    ref: maskAnimationInnerRef,
    from: {
      transform: `translate3d(0, -90%, 0)`
    },
    to: {
      transform: `translate3d(0, 0%, 0)`
    },
    config: config.slow
  })

  useChain(
    toggle ? [maskAnimationRef, maskAnimationInnerRef, lineAnimationRef, titleAnimationRef] : [],
    [0, 0, 0, 0.5]
  )

  const params = {
    Swiper,
    slidesPerView: 'auto',
    centeredSlides: false,
    spaceBetween: 30,
    speed: 1000,
    slideToClickedSlide: true,
    threshold: 20,
    grabCursor: true,
    breakpoints: {
      850: {
        centeredSlides: true,
        spaceBetween: 100
      }
    }
  }

  const goNext = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  return (
    <Outer ref={ref}>
      <InView element={ref.current} toggle={toggle} setToggle={setToggle} />
      <SwiperOverlayArrows>
        <SwiperOverlayArrowsInner>
          <SwiperNext onClick={() => goPrev()} />
          <SwiperPrev onClick={() => goNext()} />
        </SwiperOverlayArrowsInner>
      </SwiperOverlayArrows>
      <Inner>
        <Line style={lineAnimation} />
        <Title style={titleAnimation}>Gallery</Title>
      </Inner>
      <CarouselContainer>
        <CarouselOuter style={maskAnimation}>
          <CarouselInner style={maskAnimationInner}>
            <ReactIdSwiperCustom getSwiper={updateSwiper} {...params}>
              {imageCarousel.map((image, index) => (
                <div key={index}>
                  <ImageContainer>
                    <Image
                      fluid={image.imageFile.childImageSharp.fluid}
                      alt={image.altText}
                      style={{ position: 'absolute' }}
                    />
                  </ImageContainer>
                  <Caption dangerouslySetInnerHTML={{ __html: image.caption }} />
                </div>
              ))}
            </ReactIdSwiperCustom>
          </CarouselInner>
        </CarouselOuter>
      </CarouselContainer>
    </Outer>
  )
}

export default ImageCarousel

const Outer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 15vw 0 0;

  @media (max-width: 650px) {
    margin-top: 32vw;
  }

  .swiper-container {
    width: 100%;

    img {
      opacity: 1 !important;
    }
  }

  .swiper-slide {
    width: 67%;
    opacity: 0.2;
    transition: opacity 0.6s ease;
    background-color: ${props => props.theme.colours.darkGrey};

    @media (max-width: 850px) {
      width: 85%;
    }

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(61% + 3.5rem);

      @media (max-width: 850px) {
        padding-bottom: 100%;
      }
    }

    &.swiper-slide-active {
      opacity: 1;
    }
  }
`

const Inner = styled.div`
  position: relative;
  width: 67%;
  margin: 0 auto;

  @media (max-width: 1260px) {
    width: 75%;
  }

  @media (max-width: 650px) {
    width: calc(100% - 60px);
  }
`

const Line = styled(animated.div)`
  position: absolute;
  top: -11rem;
  left: -10rem;
  width: 6rem;
  height: 1px;
  background-color: #707070;
  transform-origin: left;

  @media (max-width: 850px) {
    left: 0;
  }

  @media (max-width: 650px) {
    top: -7rem;
  }
`

const Title = styled(animated.h2)`
  position: absolute;
  top: -6rem;
  left: -10rem;
  font-size: 9.5rem;
  color: ${props => props.theme.colours.orange};
  transform-origin: top left;
  letter-spacing: -0.1rem;
  z-index: 5;

  @media (max-width: 850px) {
    left: 0;
  }

  @media (max-width: 650px) {
    top: -3.3rem;
    font-size: 6.5rem;
  }
`

const CarouselContainer = styled(animated.div)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
`

const CarouselOuter = styled(animated.div)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const CarouselInner = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`

const ImageContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 3.5rem);

  @media (max-width: 650px) {
    height: 100%;
  }
`

const Image = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Caption = styled.aside`
  position: absolute;
  left: 0;
  bottom: 0;

  p {
    display: block;
    color: #000;
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    line-height: 2;
    margin: 1rem 0 0;
    opacity: 0.3;
  }

  @media (max-width: 650px) {
    display: none;
  }
`

const SwiperOverlayArrows = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SwiperOverlayArrowsInner = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 67%;
  height: 100%;
  margin: auto;

  @media (max-width: 850px) {
    width: 85%;
  }

  @media (max-width: 750px) {
    display: none;
    pointer-events: none;
  }
`

const SwiperArrow = styled.figure`
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 5;
`

const SwiperNext = styled(SwiperArrow)`
  right: 0;
  cursor: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHdpZHRoPSIxMDBweCIgYmFzZVByb2ZpbGU9InRpbnkiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgoJIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTI3LjkgNjEuMSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9IiNENTY1MUIiIGQ9Ik0wLDMyLjloMTE4LjZjLTguMyw4LjMtMTYuNCwxNi41LTI0LjUsMjQuNmMxLjIsMS4yLDIuNCwyLjMsMy42LDMuNWMwLjEtMC4xLDAuMy0wLjMsMC40LTAuNQoJYzkuOC05LjgsMTkuNS0xOS41LDI5LjMtMjkuM2MwLjItMC4yLDAuNC0wLjMsMC42LTAuNXYtMC4xYy0xMC0xMC0yMC4xLTIwLjEtMzAuMS0zMC4xYy0wLjEtMC4xLTAuMi0wLjMtMC4zLTAuNGgtMC4xCgljLTEuMSwxLjItMi4zLDIuNC0zLjYsMy43YzguMiw4LjIsMTYuNCwxNi40LDI0LjUsMjQuNUgwVjMyLjl6Ii8+Cjwvc3ZnPgo=')
      100 0,
    pointer;
`

const SwiperPrev = styled(SwiperArrow)`
  left: 0%;
  cursor: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHdpZHRoPSIxMDBweCIgYmFzZVByb2ZpbGU9InRpbnkiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgoJIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTI3LjkgNjEuMSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9IiNENTY1MUIiIGQ9Ik0xMjgsMjguMUg5LjRjOC4zLTguMywxNi40LTE2LjUsMjQuNS0yNC42Yy0xLjItMS4yLTIuNC0yLjMtMy42LTMuNWMtMC4xLDAuMS0wLjMsMC4zLTAuNCwwLjUKCWMtOS44LDkuOC0xOS41LDE5LjUtMjkuMywyOS4zQzAuNCwzMCwwLjIsMzAuMSwwLDMwLjN2MC4xYzEwLDEwLDIwLjEsMjAuMSwzMC4xLDMwLjFjMC4xLDAuMSwwLjIsMC4zLDAuMywwLjRoMC4xCgljMS4xLTEuMiwyLjMtMi40LDMuNi0zLjdDMjUuOSw0OS4xLDE3LjgsNDEsOS42LDMyLjhIMTI4VjI4LjF6Ii8+Cjwvc3ZnPgo=')
      100 0,
    pointer;
`
